import React, { useState, Fragment, useEffect } from "react"
import { Form, Formik } from "formik"
// import classNames from "classnames"

import Layout from "layout/Layout"
import Container from "layout/Container"
import Section from "elements/Section"

import FormStyledRadio from "../Elements/Form/FormStyledRadio"
import { careerRoleOptions } from "./utils/careerRoleOptions"
import { useCareerImages } from "./utils/useCareersImages"

const Careers = ({ pageContext }) => {
  const [formLink, setFormLink] = useState(false)
  const data = useCareerImages()
  console.log(pageContext.links)
  useEffect(() => {
    if (pageContext.links.length === 1) {
      setFormLink(pageContext.links[0].formLink)
    }
  }, [])

  if (formLink || pageContext.links.length === 1) {
    return (
      <Layout
        title={pageContext?.module?.title}
        subtitle={pageContext?.module?.subtitle}
        seoTitle={pageContext?.module?.seoTitle}
      >
        <Container isCentered>
          <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js" />
          <div>
            <iframe
              className="airtable-embed airtable-dynamic-height mt-2 "
              src={formLink}
              frameborder="0"
              onmousewheel=""
              width="100%"
              height="2000"
              title={formLink}
            />
          </div>
        </Container>
      </Layout>
    )
  }
  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
    >
      <Container isCentered>
        <Section title="Which team are you interested in joining?">
          <Formik
            initialValues={{ role: "" }}
            // validationSchema={{}}
            // onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                {careerRoleOptions.map((option) => {
                  return (
                    <Fragment>
                      <FormStyledRadio
                        isActive={false}
                        onClick={() => {
                          console.log(option.link)
                          setFormLink(option.link)
                        }}
                        logo={data[option.logo].childImageSharp.fixed}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: option?.description,
                          }}
                          className="content is-size-4-desktop is-size-6-mobile"
                        />
                      </FormStyledRadio>
                    </Fragment>
                  )
                })}
              </Form>
            )}
          </Formik>
        </Section>
      </Container>
    </Layout>
  )
}

export default Careers
