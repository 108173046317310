export const careerRoleOptions = [
  {
    name: "hq",
    label: "Head Office",
    link: "https://mdgr.cr/apply-embed",
    description: "Head Office",
    logo: "hq",
  },
  {
    name: "medstaff",
    label: "Medical Staff",
    link: "https://mdgr.cr/ms-apply-embed",
    description: "Medical Staff",
    logo: "medstaff",
  },
]
